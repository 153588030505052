<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
<template>
  <div>
    <b-form @submit.prevent="formSubmitted">
      <b-row>
        <b-col md="6">
          <b-form-group label="Transaction Type" label-for="trxType">
            <b-form-select
              id="trxType"
              name="trxType"
              v-model="transaction.type"
              :options="types"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Transaction Date" label-for="trxDate">
            <b-form-datepicker
              id="trxDate"
              v-model="transaction.date"
              class="mb-1"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="From Warehouse" label-for="fromWarehouse">
            <b-form-select
              id="fromWarehouse"
              name="fromWarehouse"
              v-model="transaction.fromWarehouse"
              :options="warehouses"
              value-field="id"
              text-field="name"
              disabled
            >
              <option value="">None</option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="To Warehouse" label-for="toWarehouse">
            <b-form-select
              id="toWarehouse"
              name="toWarehouse"
              v-model="transaction.toWarehouse"
              :options="warehouses"
              value-field="id"
              text-field="name"
              disabled
            >
              <option value="">None</option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mb-2">
          <label for="trxRef">Transaction Reference</label>
          <b-form-textarea
            id="trxRef"
            name="trxRef"
            placeholder="Adjustment for case A05"
            rows="3"
            v-model="transaction.refNo"
            disabled
          />
        </b-col>
        <b-col cols="12" class="mb-2">
          <div class="d-flex justify-content-center mb-1">
            <b-spinner
              variant="success"
              label="Spinning"
              block
              v-if="
                loading ||
                productLoading ||
                materialLoading ||
                uomLoading ||
                warehouseLoading
              "
              style="width: 3rem; height: 3rem"
            ></b-spinner>
          </div>
        </b-col>
      </b-row>
    </b-form>
    <b-form
      ref="form"
      :style="{ height: trHeight }"
      class="repeater-form"
      @submit.prevent="addLine"
    >
      <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>
      <b-row
        v-for="item in transaction.inventoryTransactionLine"
        :id="item.serial"
        :key="item.serial"
        ref="row"
      >
        <b-col md="3">
          <b-form-group label="Product / Raw Material" label-for="itemId">
            <b-form-select
              v-model="item.itemId"
              id="itemId"
              name="itemId"
              :options="items"
              value-field="id"
              text-field="name"
              disabled
            />
          </b-form-group>
        </b-col>
        <!-- Quantity -->
        <b-col md="2">
          <b-form-group label="Unit of Measure" label-for="itemUom">
            <b-form-select
              v-model="item.uomId"
              id="itemUom"
              name="itemUom"
              :options="itemUoms"
              value-field="id"
              text-field="name"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Qty" label-for="itemQty">
            <b-form-input
              v-model="item.qty"
              id="itemQty"
              name="itemQty"
              type="number"
              min="1"
              max="100"
              placeholder=""
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>    
<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from "bootstrap-vue";

import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormTextarea,
    BFormDatepicker,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      transaction: {},
      items: [],
      types: [
        { value: 1, text: "PURCHASE" },
        { value: 2, text: "PRODUCTION" },
        { value: 3, text: "SALES" },
        { value: 4, text: "RETURN" },
        { value: 5, text: "DEFECT" },
        { value: 6, text: "LOST" },
        { value: 7, text: "STOLEN" },
      ],
    };
  },
  methods: {
    ...mapActions("inventoryTransactionModule", ["getTransactionByIdAction"]),
    ...mapActions("productModule", ["getProductListAction"]),
    ...mapActions("rawmaterialModule", ["getRawMaterialListAction"]),
    ...mapActions("itemUomModule", ["getItemUOMListAction"]),
    ...mapActions("warehouseModule", ["getWarehouseListAction"]),
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    created() {
      window.addEventListener("resize", this.initTrHeight);
    },
    destroyed() {
      window.removeEventListener("resize", this.initTrHeight);
    },
  },
  computed: {
    ...mapGetters("inventoryTransactionModule", {
      loading: "loading",
    }),
    ...mapGetters("productModule", {
      ProductList: "product",
      productLoading: "loading",
    }),
    ...mapGetters("rawmaterialModule", {
      RawMaterialList: "rawmaterials",
      materialLoading: "loading",
    }),
    ...mapGetters("itemUomModule", {
      itemUoms: "itemuom",
      uomLoading: "loading",
    }),
    ...mapGetters("warehouseModule", {
      warehouses: "warehouse",
      warehouseLoading: "loading",
    }),
  },
  async mounted() {
    await this.getWarehouseListAction();
    await this.getProductListAction().then(() => {
      this.ProductList.map((x) => {
        if (x.defaultUomId) {
          this.items.push({
            id: x.id,
            name: x.name,
            defaultUomID: x.defaultUomId,
          });
        }
      });
    });
    await this.getRawMaterialListAction().then(() => {
      this.RawMaterialList.map((x) => {
        if (x.defaultUomID) {
          this.items.push({
            id: x.id,
            name: x.name,
            defaultUomID: x.defaultUomID,
          });
        }
      });
    });
    await this.getItemUOMListAction();
    this.transaction = await this.getTransactionByIdAction(
      this.$route.params.id
    );
    this.initTrHeight();
  },
};
</script>